<template>
  <LgcModule
      class="lgc-module-title"
      @delete="$emit('delete', $event)"
      @add-below="$emit('add-below', $event)">
    <h2 contentEditable="true" @input="inputEvent" v-text="dataValue"></h2>
  </LgcModule>
</template>

<script>
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
export default {
  name: "LgcModuleTitle",
  components: { LgcModule },
  props: {
    value: String
  },
  data() {
    return {
      dataValue: this.value
    }
  },
  methods: {
    inputEvent(e) {
      this.$emit('input', {
        value: e.target.innerText
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lgc-module-title {
  h2 {
    margin: 0;
  }
}
</style>
