<template>
  <div>
    <LgcModule
        v-bind="$attrs"
        class="lgc-module-button"
        @delete="$emit('delete', $event)"
        @add-below="$emit('add-below', $event)">
      <button
          @focusout="dataButtonText = $event.target.innerText; emitInput()"
          @click.prevent.stop
          @dblclick="onDoubleClick"
          @keydown.space.prevent="buttonInsertSpace"
          @keydown.enter.prevent="buttonInsertEnter"
          type="button"
          v-text="dataButtonText"
          :style="{ backgroundColor: this.dataButtonColor, borderColor: this.dataButtonColor }"
          contenteditable="true">
      </button>
    </LgcModule>
    <CModal
        title="Button bearbeiten"
        size=""
        :show.sync="modalVisible"
        :closeOnBackdrop="true"
    >
      <div class="lgc-module-button--edit">
        <CRow>
          <CCol>
            <ColorPicker label="Primär" v-model="dataButtonColor" />
          </CCol>
        </CRow>
      </div>
      <div slot="footer">
        <CButton
            class="mr-3"
            color="success"
            variant="outline"
            @click="modalVisible = false"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import ColorPicker from "@/components/ColorPicker";
import _ from "lodash";

export default {
  name: "LgcModuleButton",
  components: {
    ColorPicker,
    LgcModule
  },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      modalVisible: false,
      dataButtonText: this.value.buttonText,
      dataButtonColor: this.value.buttonColor || '#2d78a9'
    }
  },
  methods: {
    buttonInsertSpace() {
      document.execCommand("insertText", false, ' ')
    },
    buttonInsertEnter() {
      document.execCommand("insertText", false, "\n")
    },
    emitInput() {
      this.$emit('input', {
        value: {
          buttonText: _.trim(this.dataButtonText),
          buttonColor: _.trim(this.dataButtonColor)
        }
      })
    },
    onDoubleClick() {
      this.modalVisible = true
    }
  },
  watch: {
    dataButtonColor() {
      this.emitInput()
    }
  }
}
</script>

<style lang="scss" scoped>
.lgc-module-button {
  margin: 0;

  button {
    margin-top: 0;
    border: 1px solid #1c6ca1;
    background-color: #2d78a9;
    color: #fff;
    font-size: 20px;
    padding: 6px 14px;
    border-radius: 4px;
    outline: 0;

    &:hover {
      border: 1px solid #1d608c;
      background-color: #226694;
    }
  }
}
</style>
