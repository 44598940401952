<template>
  <div>
    <CRow>
      <CCol sm="12" md="4" lg="4" xl="3">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <slot name="header">
                  <div class="prt-card-header">
                    <div class="header-naming">
                      <CIcon name="cil-window-restore"/>
                      Funnel-Schritte
                    </div>
                  </div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <div class="list-group-wrapper">
                  <!--<h4>Alle Seiten</h4>-->
                  <LeadgeneratorOrderDraggable
                      :list="pagesComputed"
                      :activePage="activePage"
                      @newPage="addNewPage"
                      @deleteCurrentPage="deleteCurrentPage"
                      @pageClick="activePage = $event"
                      @pageDragMouseUp="reorderPages"
                      @changePageName="changePageName"
                  />
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CRow>
              <CCol>
                <CCard>
                  <CCardHeader>
                    <slot name="header">
                      <div class="prt-card-header">
                        <div class="header-naming">
                          <CIcon name="cil-window-restore"/>
                          Metadaten
                        </div>
                      </div>
                    </slot>
                  </CCardHeader>
                  <CCardBody>
                    <CForm ref="form">
                      <CInput
                          type="text"
                          label="Name"
                          v-model="name"
                          description="Interne Bezeichung des Funnels"
                          required />
                      <CInput
                          type="text"
                          label="Google Tag Manager ID"
                          v-model="gtmId"
                          description="(optional)"
                          placeholder="GTM-XXXXXXX" />
                      <CInput
                          type="text"
                          label="Facebook Pixel ID"
                          v-model="facebookPixelId"
                          description="(optional)"
                          placeholder="" />
                      <NewsletterGroupSelector
                          v-model="groups"
                          description="Hier können Sie die Zielgruppe(n) auswählen denen der Lead zugeordnet wird."
                      />
                      <ImageUploadField
                          v-model="faviconUrl"
                          :horizontal="false"
                          label="Favicon"
                          description="Das Favicon muss 32x32px groß sein und die Dateigröße darf nicht größer sein als 48KB."
                          style="margin-bottom: 18px"
                          :max-image-size="{ width: 32, height: 32, fileSizeInKB: 48 }"
                      />
                      <CodeEditor
                          label="Eigenen Header"
                          description="Individuelles HTML in den <head> der Wertermittlung einfügen."
                          v-model="customHeader"
                          language="markup"
                      />
                      <div style="text-align: right; margin-bottom: 8px">
                        <CButton type="button" @click.prevent="submitLeadgen" color="success">{{ btnText }}</CButton>
                      </div>
                      <div v-if="id" style="text-align: right; margin-bottom: 8px">
                        <CButton type="button" @click.prevent="deleteLeadgen" color="danger">Löschen</CButton>
                      </div>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow></CRow>
      </CCol>

      <CCol sm="12" md="8" lg="8" xl="9">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <slot name="header">
                  <div class="prt-card-header">
                    <div class="header-naming">
                      <CIcon name="cil-window-maximize"/>
                      Inhalt
                    </div>
                    <div class="header-button">
                      <CButton
                          v-if="!createTemplateProcessing"
                          @click="openCreateTemplateModal"
                          style="margin-right: 6px"
                          color="secondary"
                          size="sm">
                        <CIcon name="cil-note-add"/>
                        <span>&nbsp;Vorlage erstellen</span>
                      </CButton>
                      <CButton v-else style="margin-right: 6px" color="warning" class="mouse-cursor-loading" disabled>Bitte warten...</CButton>
                      <CButton
                          @click="openTemplatesModal"
                          style="margin-right: 6px"
                          color="dark"
                          size="sm">
                        <CIcon name="cil-folder-open"/>
                        <span>&nbsp;Vorlage verwenden...</span>
                      </CButton>
                      <CButton
                          @click="openSettingsModal"
                          name="backagain"
                          size="sm"
                          key="key"
                          color="dark">
                        <CIcon size="sm" name="cil-settings"/>
                        <span>&nbsp;Einstellungen</span>
                      </CButton>
                    </div>
                  </div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <div class="leadgenerator-content">
                  <div class="leadgenerator-content-block" :style="computedLeadgeneratorContentBlockStyles">
                    <div v-for="component in lgcModulesComputed" :key="`lgcModules-${activePage + 1}-${component.id}`">
                      <component
                          v-bind:is="component.type"
                          v-bind="component.props"
                          @input="component.props = $event"
                          @delete="deleteModule(component.id)"
                          @add-below="openAddModuleModal(component.id)"
                      >
                      </component>
                    </div>
                    <div class="leadgenerator-content-more">
                      <CButton @click="openAddModuleModal" shape="pill" color="info"><CIcon name="cil-plus" size="lg"/></CButton>
                    </div>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow v-if="id">
          <CCol>
            <CCard>
              <CCardHeader>
                <slot name="header">
                  <div class="prt-card-header">
                    <div class="header-naming">
                      <CIcon name="cil-window-restore"/>
                      Dein Funnel ist Live
                    </div>
                  </div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <p>Klicke auf den Button, um den Funnel aufzurufen. Du kannst diesen direkt nutzen, das einfügen des Funnels in deine Webseite mit dem Code weiter unten ist optional.</p>
                <br />
              <CButton
                  class="ml-2 mr-1"
                  :href="`${leadgenBaseUrl}/${ iframeKey }/${ id }/`"
                  target="_blank"
                  type="button"
                  name="open_funnel"
                  color="info">
                <CIcon size="sm" class="mr-2" name="cil-share-alt"/>
                <span>Lead-Funnel aufrufen</span>
              </CButton>

              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow v-if="id">
          <CCol>
            <CCard>
              <CCardHeader>
                <slot name="header">
                  <div class="prt-card-header">
                    <div class="header-naming">
                      <CIcon name="cil-window-restore"/>
                      Code
                    </div>
                  </div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <strong>Installation des Lead-Funnels auf Ihre Webseite</strong>
                <p>Fügen Sie diesen Integrationscode einfach per Copy & Paste zu jeder Seite, auf der der Funnel angezeigt werden soll auf Ihrer Webseite hinzu.</p>
                <textarea readonly="" type="text" class="form-control">&lt;script type=&quot;text/javascript&quot; src=&quot;{{ iframeUrl }}/leadgenerator/{{ id }}/script?key={{ iframeKey }}&quot;&gt;&lt;/script&gt;&lt;div id=&quot;prt-leadgen-iframe-placeholder&quot;&gt;&lt;/div&gt;</textarea>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CModal
        title="Neuen Abschnitt hinzufügen..."
        size="lg"
        :show.sync="visibleAddModal"
        :closeOnBackdrop="false"
    >
      <div class="lgc-add-module-list">
        <CCard v-for="module in addableModules" @click="appendNewModule(module)" :key="'addableModule-'+module.title">
          <CCardBody>
            <CImg class="lgc-add-module-list-image" :src="module.imgSrc"/>
          </CCardBody>
          <CCardFooter>
            {{ module.title }}
          </CCardFooter>
        </CCard>
      </div>
      <div slot="footer">
        <CButton
            class="mr-3"
            color="dark"
            variant="outline"
            @click="visibleAddModal = false"
        >
          Abbrechen
        </CButton>
      </div>
    </CModal>
    <CModal
        title="Einstellungen"
        size="lg"
        :show.sync="visibleSettingsModal"
        :closeOnBackdrop="false"
    >
      <PrtFontPicker
          label="Schriftart"
          name="leadgenFontPicker"
          v-model="settings.font"
      />
      <div slot="footer">
        <CButton
            class="mr-3"
            color="success"
            variant="outline"
            @click="visibleSettingsModal = false"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
    <CModal
        title="Vorlage auswählen"
        size="xl"
        :show.sync="visibleTemplatesModal"
        :closeOnBackdrop="false"
    >
      <CTabs>
        <CTab title="Vorlagen">
          <div class="leadgenerator-template-wrapper">
            <div v-for="template in leadgeneratorGlobalTemplates" @click="loadTemplate(template)" class="leadgenerator-template">
              <CImg :src="template.thumbnailUrl"/>
              <label>{{ template.name }}</label>
              <CButton v-if="$commons.isSystemAdmin()" @click.stop.prevent="deleteTemplate(template)" size="sm" color="danger" class="deletable"><CIcon size="sm" name="cil-trash"></CIcon></CButton>
            </div>
          </div>
        </CTab>
        <CTab title="Eigene Vorlagen">
          <div class="leadgenerator-template-wrapper">
            <div v-for="template in leadgeneratorTemplates" @click="loadTemplate(template)" class="leadgenerator-template">
              <CImg :src="template.thumbnailUrl"/>
              <label>{{ template.name }}</label>
              <CButton @click.stop.prevent="deleteTemplate(template)" size="sm" color="danger" class="deletable"><CIcon size="sm" name="cil-trash"></CIcon></CButton>
            </div>
          </div>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton color="secondary" @click="visibleTemplatesModal = false">Schließen</CButton>
      </template>
    </CModal>
    <CModal
        title="Vorlage erstellen"
        size="sm"
        :show.sync="visibleCreateTemplateModal"
        :closeOnBackdrop="true"
    >
      <CForm>
        <CInput v-model="templateCreateName" required label="Name der Vorlage"/>
        <CInputCheckbox
            v-if="$commons.isSystemAdmin()"
            label="Globale Vorlage?"
            description="Dies ist eine Admin-Funktion."
            id="active"
            name="active"
            value="Aktiv"
            :custom="true"
            :checked.sync="templateCreateIsGlobal"
            :inline="false"
        />
      </CForm>
      <template #footer>
        <CButton color="secondary" @click="visibleCreateTemplateModal = false">Abbrechen</CButton>
        <CButton v-if="!createTemplateProcessing" color="success" @click="createLeadgeneratorTemplateFromCurrentDesign">Erstellen</CButton>
        <CButton v-else color="warning" disabled>Bitte warten...</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import _ from 'lodash'
import commons from "@/commons";
import axios from "axios";
import html2canvas from "html2canvas"
import LeadgeneratorOrderDraggable from "@/components/LeadgeneratorOrderDraggable";
import LgcModuleQuiz from "@/views/base/leadgenerator/lgc-modules/LgcModuleQuiz";
import LgcModuleText from "@/views/base/leadgenerator/lgc-modules/LgcModuleText";
import LgcModuleTitle from "@/views/base/leadgenerator/lgc-modules/LgcModuleTitle";
import LgcModuleImage from "@/views/base/leadgenerator/lgc-modules/LgcModuleImage";
import LgcModulePersonalInfo from "@/views/base/leadgenerator/lgc-modules/LgcModulePersonalInfo";
import LgcModuleButton from "@/views/base/leadgenerator/lgc-modules/LgcModuleButton";
import LgcModuleChoices from "@/views/base/leadgenerator/lgc-modules/LgcModuleChoices";
import LgcModuleInput from "@/views/base/leadgenerator/lgc-modules/LgcModuleInput";
import LgcModuleConditionsPrivacy from "@/views/base/leadgenerator/lgc-modules/LgcModuleConditionsPrivacy";
import LgcModuleInputRange from "@/views/base/leadgenerator/lgc-modules/LgcModuleInputRange";
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";
import PrtFontPicker from "@/components/PrtFontPicker";
import CodeEditor from "@/components/CodeEditor";
import ImageUploadField from "@/components/ImageUploadField";

const defaultContentJson = {
  "version": "1",
  "metadata": {
    "name": "Termin vereinbaren",
    "tracking": {}
  },
  "pages": [
    {
      "name": "Der Anfang",
      "id": 1,
      "elements": [
        { "id": 1, "type": "LgcModuleTitle", "props": { "value": "Das ist der Anfang ✌" } },
        { "id": 2, "type": "LgcModulePersonalInfo", "props": { "value": { "header": "Hier können Sie einen einladenden schönen Text verfassen.", "buttonText": "Jetzt weiter machen!", "conditionsPrivacyText": "Ihr Datenschutz ist uns wichtig. " + commons.getCurrentRealm().name + " nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte, Produkte und Dienstleistungen zu kontaktieren. Sie können sich jederzeit von jeglicher Kommunikation seitens " + commons.getCurrentRealm().name + " abmelden. Weitere Informationen finden Sie in unserer Datenschutzerklärung." } } },
      ]
    },
    {
      "name": "Alles Papier?",
      "id": 2,
      "elements": [
        { "id": 1, "type": "LgcModuleTitle", "props": { "value": "Alles Papier - aber warum?" } },
        { "id": 2, "type": "LgcModuleText", "props": { "value": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." } },
        { "id": 3, "type": "LgcModuleQuiz", "props": { "value": [
              { "id": 1, "title": "Nur Digital", "image": "https://picsum.photos/seed/1/400/200" },
              { "id": 2, "title": "Wenig Papier", "image": "https://picsum.photos/seed/2/400/200" },
              { "id": 3, "title": "Viel Papier", "image": "https://picsum.photos/seed/3/400/200" },
              { "id": 4, "title": "Digital - was das?", "image": "https://picsum.photos/seed/4/400/200" }
            ] }
        }
      ]
    },
    {
      "name": "Ende",
      "id": 3,
      "elements": [
        { "id": 1, "type": "LgcModuleTitle", "props": { "value": "Danke und Bye!" } },
        { "id": 2, "type": "LgcModuleText", "props": { "value": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." } },
        { "id": 3, "type": "LgcModuleImage", "props": { "value": "https://picsum.photos/id/1025/400/700" } }
      ]
    }
  ]
}

export default {
  name: "Leadgenerator",
  components: {
    ImageUploadField,
    PrtFontPicker,
    NewsletterGroupSelector,
    LgcModuleTitle,
    LgcModuleText,
    LgcModuleQuiz,
    LgcModuleImage,
    LeadgeneratorOrderDraggable,
    LgcModulePersonalInfo,
    LgcModuleButton,
    LgcModuleChoices,
    LgcModuleInput,
    LgcModuleInputRange,
    LgcModuleConditionsPrivacy,
    CodeEditor
  },
  props: {
    passedData: {
      type: Object,
      default: () => { return {} }
    },
    btnText: String
  },
  data() {
    return {
      activePage: 0,
      visibleAddModal: false,
      visibleSettingsModal: false,
      visibleTemplatesModal: false,
      visibleCreateTemplateModal: false,
      insertNewModuleAfterId: -1,
      iframeUrl: process.env.VUE_APP_IFRAME_URL,
      iframeKey: commons.getFromStorage('currentRealm').iframeKey,
      addableModules: [
        { title: 'Titel', imgSrc: 'img/title.png' },
        { title: 'Text', imgSrc: 'img/textbearbeitung.png' },
        { title: 'Quiz', imgSrc: 'img/wahlen.png' },
        { title: 'Bild', imgSrc: 'img/bild.png' },
        { title: 'Formular', imgSrc: 'img/business-cards.png' },
        { title: 'Button', imgSrc: 'img/press-button.png' },
        { title: 'Auswahl', imgSrc: 'img/list.png' },
        { title: 'Eingabefeld', imgSrc: 'img/input.png' },
        { title: 'Slidebar', imgSrc: 'img/range.png' },
        { title: 'AGB & Datenschutz', imgSrc: 'img/conditions-privacy.png' },
      ],
      id: this.passedData.id,
      contentJson: this.passedData.contentJson || defaultContentJson,
      name: this.passedData.name || '',
      gtmId: this.passedData.gtmId || '',
      facebookPixelId: this.passedData.facebookPixelId || '',
      groups: this.passedData.groups || [],
      leadgenBaseUrl: process.env.VUE_APP_LEADGEN_BASE_URL,
      customHeader: '',
      faviconUrl: '',
      settings: {},
      leadgeneratorTemplates: [],
      leadgeneratorGlobalTemplates: [],
      templateCreateName: '',
      templateCreateIsGlobal: false,
      createTemplateProcessing: false,
      screenshotBlob: null,
    }
  },
  created() {
    this.settings = this.contentJson.settings || {
      font: 'Open Sans'
    }
    this.customHeader = this.contentJson.metadata ? this.contentJson.metadata.tracking ? this.contentJson.metadata.tracking.customHeader : '' : ''
    this.faviconUrl = this.contentJson.metadata ? this.contentJson.metadata.faviconUrl : ''
    this.fetchAllTemplates()
  },
  computed: {
    pagesComputed(newVal, oldVal) {
      return this.contentJson.pages.map(page => page.name)
    },
    lgcModulesComputed(newVal, oldVal) {
      const currentPage = this.contentJson.pages[this.activePage]
      if (currentPage) {
        return currentPage.elements
      }
      return []
    },
    computedLeadgeneratorContentBlockStyles() {
      return {
        fontFamily: this.settings.font
      }
    }
  },
  methods: {
    openAddModuleModal(insertAfterId) {
      this.visibleAddModal = true
      if (Number.isInteger(insertAfterId)) this.insertNewModuleAfterId = insertAfterId
    },
    appendNewModule(module) {
      const currentPage = this.contentJson.pages[this.activePage]
      const elementsSize = currentPage.elements.length
      const newId = elementsSize + 1
      let newModule = null
      switch (module.title) {
        case 'Titel':
          newModule = {
            id: newId,
            type: 'LgcModuleTitle',
            props: { value: 'Lorem ipsum...' }
          }
          break
        case 'Text':
          newModule = {
            id: newId,
            type: 'LgcModuleText',
            props: { value: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua' }
          }
          break
        case 'Quiz':
          newModule = {
            id: newId,
            type: 'LgcModuleQuiz',
            props: { value: [
                { "id": 1, "title": "Card 1", "image": "https://picsum.photos/seed/1/400/200" },
                { "id": 2, "title": "Card 2", "image": "https://picsum.photos/seed/2/400/200" },
                { "id": 3, "title": "Card 3", "image": "https://picsum.photos/seed/3/400/200" },
                { "id": 4, "title": "Card 4", "image": "https://picsum.photos/seed/4/400/200" }
              ]}
          }
          break
        case 'Bild':
          newModule = {
            id: newId,
            type: 'LgcModuleImage',
            props: { value: 'https://picsum.photos/seed/'+(Math.floor(Math.random() * 1000))+'/400/200' }
          }
          break
        case 'Formular':
          newModule = {
            id: newId,
            type: 'LgcModulePersonalInfo',
            props: {
              value: {
                header: "Ein Strategiegespräch ist für Sie unverbindlich und kostenfrei",
                buttonText: "Jetzt Kontakt aufnehmen!",
                conditionsPrivacyTextSize: '12px',
                conditionsPrivacyText: "Ihr Datenschutz ist uns wichtig. " + commons.getCurrentRealm().name + " nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte, Produkte und Dienstleistungen zu kontaktieren. Sie können sich jederzeit von jeglicher Kommunikation seitens " + commons.getCurrentRealm().name + " abmelden. Weitere Informationen finden Sie in unserer Datenschutzerklärung."
              }
            }
          }
          break
        case 'Button':
          newModule = {
            id: newId,
            type: 'LgcModuleButton',
            props: { value: { buttonText: "Klick mich!" } }
          }
          break
        case 'Auswahl':
          newModule = {
            id: newId,
            type: 'LgcModuleChoices',
            props: { value: [
                { id: 1, title: "Choice 1" },
                { id: 2, title: "Choice 2" },
                { id: 3, title: "Choice 3" },
                { id: 4, title: "Choice 4" }
            ]}
          }
          break
        case 'Eingabefeld':
          newModule = {
            id: newId,
            type: 'LgcModuleInput',
            props: { value: { inputText: "Dies ist ein schönes Eingabefeld!", inputPlaceholder: "Ein cooles Placeholder", inputRequired: true } }
          }
          break
        case 'Slidebar':
          newModule = {
            id: newId,
            type: 'LgcModuleInputRange',
            props: { value: { inputText: "Dies ist ein schöner Slidebar!", inputMin: 0, inputMax: 100, inputStep: 1, inputRequired: true } }
          }
          break
        case 'AGB & Datenschutz':
          newModule = {
            id: newId,
            type: 'LgcModuleConditionsPrivacy',
            props: { value: { conditionsPrivacyActive: true, conditionsPrivacyTextSize: '12px', conditionsPrivacyText: "Ihr Datenschutz ist uns wichtig. " + commons.getCurrentRealm().name + " nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte, Produkte und Dienstleistungen zu kontaktieren. Sie können sich jederzeit von jeglicher Kommunikation seitens " + commons.getCurrentRealm().name + " abmelden. Weitere Informationen finden Sie in unserer Datenschutzerklärung." } }
          }
          break
        default:
          break
      }
      if (newModule) {
        console.log(this.insertNewModuleAfterId)
        if (this.insertNewModuleAfterId !== -1) {
          // insertNewModuleAfterId is already an index with +1 so no need to do -1 and +1 after it
          // because we will append it after
          currentPage.elements.splice(this.insertNewModuleAfterId, 0, newModule)
          this.reorderElementsIds()
        } else {
          currentPage.elements.push(newModule)
        }
      }
      this.visibleAddModal = false
      this.insertNewModuleAfterId = -1
    },
    addNewPage() {
      const currentPageCount = this.contentJson.pages.length
      this.contentJson.pages.push({
        "name": "Seite " + (currentPageCount + 1),
        "id": currentPageCount + 1,
        "elements": [
          { "id": 1, "type": "LgcModuleTitle", "props": { "value": "Eine frische neue Seite!" } },
        ]
      })
    },
    deleteCurrentPage() {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>Funnel-Schritt</b> (${this.contentJson.pages[this.activePage].name})`],
          async () => {
            const id = this.activePage +1
            this.contentJson.pages = _.filter(this.contentJson.pages, c => { return c.id !==  id })
            this.contentJson.pages.forEach((page, index) => {
              page.id = index + 1
            })
          }
      )
    },
    reorderPages(newPageOrder) {
      const newPages = []
      for (const key in newPageOrder) {
        const newValue = newPageOrder[key]
        newPages[key] = this.contentJson.pages[newValue]
      }
      newPages.forEach((page, index) => {
        page.id = index + 1
      })
      const newActivePage = newPages.indexOf(this.contentJson.pages[this.activePage])
      this.contentJson.pages = newPages
      this.activePage = newActivePage
    },
    reorderElementsIds() {
      const currentPage = this.contentJson.pages[this.activePage]
      if (currentPage) {
        currentPage.elements.forEach((elm, index) => {
          elm.id = index + 1
        })
      }
    },
    changePageName(index, newPageName) {
      this.contentJson.pages[index].name = newPageName
    },
    deleteModule(id) {
      const currentPage = this.contentJson.pages[this.activePage]
      currentPage.elements = _.filter(currentPage.elements, c => { return c.id !== id })
      this.reorderElementsIds()
    },
    submitLeadgen() {
      if (this.$refs.form.checkValidity()) {
        this.contentJson.settings = Object.assign({}, this.settings)
        this.$emit('submit', {
          name: this.name,
          gtmId: this.gtmId,
          facebookPixelId: this.facebookPixelId,
          contentJson: this.contentJson,
          groups: this.groups
        })
      } else {
        this.$refs.form.reportValidity()
      }
    },
    deleteLeadgen() {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>Leadgenerator</b> (${this.name})`],
          async () => {
            try {
              let resp = await axios.delete(`/leadgenerator/${this.id}`)
              this.$toaster.makeToast('Erfolgreich!', 'Der Leadgenerator wurde erfolgreich gelöscht.')
              this.$router.push({name: 'LeadgeneratorList', params: { saved: '1' }})
            } catch (e) {
              this.$toaster.makeToast('Fehler!', 'Der Leadgenerator konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')
            }
          }
      )
    },
    openSettingsModal() {
      this.visibleSettingsModal = true
    },
    openTemplatesModal() {
      this.visibleTemplatesModal = true
    },
    loadTemplate(template) {
      this.$modal.showModal(
          'default',
          'Vorlage benutzen?',
          [
            `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`
          ],
          () => {
            const contentJsonFromTemplate = JSON.parse(template.contentJson)
            this.contentJson = { pages: [] }
            setTimeout(() => {
              this.contentJson = contentJsonFromTemplate
              this.visibleTemplatesModal = false
            }, 10)
          },
          () => {

          },
          { btnCancel: 'abbrechen', btnConfirm: 'Vorlage verwenden', btnConfirmColor: 'danger' }
      )
    },
    fetchAllTemplates() {
      axios.get('/leadgeneratorTemplate/global')
          .then(response => {
            this.leadgeneratorGlobalTemplates = response.data
          })
          .catch(err => console.error('Problem while fetching leadgenerator templates', err))
      axios.get('/leadgeneratorTemplate')
          .then(response => {
            this.leadgeneratorTemplates = response.data
          })
          .catch(err => console.error('Problem while fetching leadgenerator templates', err))
    },
    screenshotLeadgeneratorContent() {
      return new Promise((resolve, reject) => {
        const captureElement = document.querySelector('.leadgenerator-content-block')
        captureElement.classList.add('html2canvas-screenshotting')
        html2canvas(captureElement, { allowTaint: false, useCORS: true, ignoreElements: (el) => el.classList.contains('leadgenerator-content-more') })
            .then(canvas => {
              captureElement.classList.remove('html2canvas-screenshotting')
              this.visibleCreateTemplateModal = true
              canvas.style.display = 'none'
              document.body.appendChild(canvas)
              return canvas
            }).then(canvas => {
              canvas.toBlob(blob => {
                canvas.remove()
                resolve(blob)
              }, 'image/jpeg', 0.85)
            }).catch(reject)
      })
    },
    openCreateTemplateModal() {
      document.body.requestPointerLock()
      this.createTemplateProcessing = true
      // do this in the next ticks.
      setTimeout(() => {
        this.screenshotLeadgeneratorContent()
            .then(blob => {
              document.exitPointerLock()
              this.screenshotBlob = blob
              this.createTemplateProcessing = false
              this.visibleCreateTemplateModal = true
            })
            .catch(err => {
              this.$toaster.makeToast('Probleme beim Erstellen einer Template-Preview. Bitte später versuchen.')
              console.error(err)
            })
      }, 1)
    },
    async createLeadgeneratorTemplateFromCurrentDesign() {
      this.createTemplateProcessing = true
      // Upload screenshot first
      const data = new FormData()
      const file = commons.blobToFile(this.screenshotBlob, 'leadgenerator-template-preview.jpg')
      data.append('file', file, file.name);
      const options = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        }
      }
      const responseFileUpload = await axios.post('/file/upload', data, options)
      const responseCreate = await axios.post(this.templateCreateIsGlobal ? '/leadgeneratorTemplate/global' : '/leadgeneratorTemplate', {
        name: this.templateCreateName,
        thumbnailUrl: responseFileUpload.data.fileDownloadUrl,
        contentJson: JSON.stringify(this.contentJson)
      })
      this.visibleCreateTemplateModal = false
      this.createTemplateProcessing = false
      this.screenshotBlob = null
      this.fetchAllTemplates()
    },
    deleteTemplate(template) {
      this.$modal.showModal(
          'delete',
          null,
          [`<b>Vorlage</b> (${template.name})`],
          async () => {
            try {
              await axios.delete('/leadgeneratorTemplate/' + template.id)
              this.$toaster.makeToast('Erfolgreich!', 'Ihre Vorlage <b>'+template.name+'</b> wurde soeben gelöscht.')
              // this.visibleTemplatesModal = false
              this.fetchAllTemplates()
            } catch (e) {
              this.$toaster.makeToast('Fehler', 'Es konnte keine Vorlage gelöscht werden. Bitte probieren Sie es später noch einmal.')
              console.log(e)
            }
          }
      )
    },
  },
  watch: {
    gtmId(newVal) {
      this.contentJson.metadata.tracking.googleTagManager = newVal
    },
    facebookPixelId(newVal) {
      this.contentJson.metadata.tracking.facebookPixel = newVal
    },
    customHeader(newVal) {
      this.contentJson.metadata.tracking.customHeader = newVal
    },
    faviconUrl(newVal) {
      this.contentJson.metadata.faviconUrl = newVal
    },
    visibleAddModal(newVal) {
      if (newVal === false) {
        this.insertNewModuleAfterId = -1
      }
    },
    settings: {
      handler(newSettings) {
        this.contentJson.settings = Object.assign({}, newSettings)
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.leadgenerator-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.leadgenerator-content-block {
  text-align: center;
  width: 70%;
  max-width: 475px;
  box-shadow: 1px -1px 47px 0px rgba(0,0,0,0.25);
  border-radius: 12px;
  margin: 12px;
  padding: 35px;

  &.html2canvas-screenshotting {
    box-shadow: none;
  }

  .modal-content {
    font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}
.leadgenerator-content-more {
  text-align: center;
  width: 100%;
  outline: none;
  padding: 18px 10px 10px;
}

.lgc-add-module-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 18px;

  .card {
    margin: 0;
    cursor: pointer;
    transition: 0.15s box-shadow ease-out;
  }
  .card-footer {
    transition: 0.15s background-color ease-out;
    text-align: center;
    font-size: 15px;
  }
  .card:hover {
    box-shadow: 5px 5px 20px rgb(0 0 0 / 50%);
    background-color: #05b9ae;
    border: 2px solid #05b9ae;
  }
  .card:hover .card-body {
    background-color: white;
    color: #fff;
  }
  .card:hover .card-footer {
    background-color: #05b9ae;
    color: #fff;
    font-weight: bold;
  }
  .lgc-add-module-list-image {
    width: 100%;
    padding: 10px;
  }
}
.leadgenerator-template-wrapper {
  position: relative;
  min-height: 0;  /* NEW */
  min-width: 0;   /* NEW; needed for Firefox */
  margin: 4px 0;
  padding: 18px;
  width: 100%;
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 22px 12px;
  overflow-y: auto;
  overflow-x: hidden;

  .leadgenerator-template {
    min-width: 0;   /* NEW; needed for Firefox */
    position: relative;
    border: 2px solid #2b3438;
    border-radius: 4px;
    width: 100%;
    height: 240px;
    min-height: 240px;
    overflow: hidden;
    transition: transform .2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
    }

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center top;
      user-select: none;
    }
    label {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      background-color: #2b3438;
      color: #fff;
      opacity: 0.8;
      font-size: 13px;
      padding: 2px 4px;
    }
    .deletable {
      position: absolute;
      top: 4px;
      right: 4px;
      margin: 0;
      opacity: 0.8;
      transition: all .2s;
      z-index: 299;
    }
    .deletable:hover {
      opacity: 1;
      color: #fff;
    }
  }

}
</style>
