<template>
  <div>
    <LgcModule
        v-bind="$attrs"
        :inside-module="insideModule"
        class="lgc-module-conditions-privacy"
        @delete="$emit('delete', $event)"
        @add-below="$emit('add-below', $event)">
      <p
          ref="pText"
          @dblclick="onDoubleClick"
          @focusout="dataConditionsPrivacyText = dataConditionsPrivacyActive ? $event.target.innerText : dataConditionsPrivacyText; emitInput()"
          contentEditable="true"
          :class="dataConditionsPrivacyActive ? '' : 'not-active'"
          v-html="dataConditionsPrivacyActive ? computedConditionsPrivacyText : '[Doppelklicken für AGB & Datenschutz]'"
          :style="{ fontSize: dataConditionsPrivacyTextSize }"
      />
    </LgcModule>
    <CModal
        title="AGB und Datenschutz"
        size=""
        :show.sync="modalVisible"
        :closeOnBackdrop="true"
    >
      <Checkbox
          v-if="insideModule"
          label=""
          name="conditionsPrivacyActive"
          :options="['Rechtstext unter dem Formular einfügen?']"
          @input="dataConditionsPrivacyActive = Array.isArray($event) && $event.includes('Rechtstext unter dem Formular einfügen?');emitInput()"
          :value="computedConditionsPrivacyActive"
          :horizontal="false"
      />
      <CInput
          :disabled="Boolean(!dataConditionsPrivacyActive)"
          type="number"
          label="Schriftgröße"
          horizontal
          :value="computedConditionsPrivacyTextSize"
          @input="dataConditionsPrivacyTextSize = $event + 'px';emitInput()"
      />
      <hr v-if="insideModule" />
      <!-- Why href="" and not to="" because modals currently live in a own VUE ECOSYSTEM (just see it via the VUE Chrome Dev Extension) -->
      <h5>Hinweis:</h5>
      <p>
        Damit unter dem Kontaktformular der Hinweis zum Datenschutz sowie bei Bedarf der Hinweis auf Ihre AGB erscheint.
        müssen Sie diese einblenden. Den Inhalt des Textes können Sie flexibel an Ihr Unternehmen anpassen.
        Das System wandelt automatisiert die Worte <b>AGB</b>, <b>Allgemeinen Geschäftsbedingungen</b> sowie <b>Datenschutzerklärung</b> in Links um.
        Die Inhalte zu den AGB sowie der Datenschutzerklärung finden sie in den <CLink href="#/settings/general" target="_blank">Grundeinstellungen</CLink> unter dem Reiter "AGB & Datenschutz".
      </p>
      <div slot="footer">
        <CButton
            class="mr-3"
            color="success"
            variant="outline"
            @click="modalVisible = false"
        >
          Schließen
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import ColorPicker from "@/components/ColorPicker";
import _ from "lodash";
import commons from '@/commons'
import WysiwygEditor from "@/components/WysiwygEditor";
import Checkbox from "@/components/Checkbox";

export default {
  name: "LgcModuleConditionsPrivacy",
  components: {
    Checkbox,
    WysiwygEditor,
    ColorPicker,
    LgcModule
  },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    },
    insideModule: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalVisible: false,
      dataConditionsPrivacyText: this.value.conditionsPrivacyText || 'Ihr Datenschutz ist uns wichtig. ' + commons.getCurrentRealm().name + ' nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte, Produkte und Dienstleistungen zu kontaktieren. Sie können sich jederzeit von jeglicher Kommunikation seitens ' + commons.getCurrentRealm().name + ' abmelden. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
      dataConditionsPrivacyActive: this.value.conditionsPrivacyActive || false,
      dataConditionsPrivacyTextSize: this.value.conditionsPrivacyTextSize || '12px'
    }
  },
  computed: {
    computedConditionsPrivacyText() {
      let computedText = this.dataConditionsPrivacyText + ""
      computedText = computedText.replace('Allgemeinen Geschäftsbedingungen', '<a href="#">Allgemeinen Geschäftsbedingungen</a>')
      computedText = computedText.replace('AGB', '<a href="#">AGB</a>')
      computedText = computedText.replace('Datenschutzerklärung', '<a href="#">Datenschutzerklärung</a>')
      return computedText
    },
    computedConditionsPrivacyActive () {
      return this.dataConditionsPrivacyActive ? ['Rechtstext unter dem Formular einfügen?'] : []
    },
    computedConditionsPrivacyTextSize() {
      return this.dataConditionsPrivacyTextSize.slice(0, -2)
    }
  },
  methods: {
    emitInput() {
      this.$emit('input', {
        value: {
          conditionsPrivacyText: _.trim(this.dataConditionsPrivacyText),
          conditionsPrivacyActive: this.dataConditionsPrivacyActive,
          conditionsPrivacyTextSize: this.dataConditionsPrivacyTextSize
        }
      })
    },
    onDoubleClick() {
      this.modalVisible = true
    }
  }
}
</script>

<style lang="scss">
.lgc-module-conditions-privacy {
  margin: 0;
  a {
    color: #321fdb !important;
  }
  p.not-active {
    opacity: 0.5;
    // text-decoration: line-through;
  }
}
</style>
