<template>
  <div>
    <LgcModule
        class="lgc-module-personalinfo"
        @delete="$emit('delete', $event)"
        @add-below="$emit('add-below', $event)">
      <p
          contenteditable="true"
          @focusout="inputEvent('dataHeader', $event)"
          v-text="dataHeader">
      </p>
      <div @dblclick="onDoubleClick" class="lgc-module-personalinfo--form">
        <input type="text" name="salutation" placeholder="Anrede*"/>
        <input type="text" name="firstName" placeholder="Vorname*"/>
        <input type="text" name="lastName" placeholder="Nachname*"/>
        <input type="text" name="email" placeholder="E-Mail*"/>
        <input type="text" name="phoneNumber" :placeholder="'Telefonnummer' + (dataPhoneNumberRequired ? '*' : '')" />
      </div>
      <LgcModuleConditionsPrivacy inside-module :value="conditionsPrivacyModuleProps.value" @input="conditionsPrivacyModuleProps = $event;emitInput()" />
      <LgcModuleButton inside-module :value="buttonModuleProps.value" @input="buttonModuleProps = $event;emitInput()" />
    </LgcModule>
    <CModal
        title="Formular bearbeiten"
        size=""
        :show.sync="modalVisible"
        :closeOnBackdrop="true"
    >
      <Checkbox
          label=""
          name="conditionsPrivacyActive"
          :options="['Telefonnummer erforderlich?']"
          @input="dataPhoneNumberRequired = Array.isArray($event) && $event.includes('Telefonnummer erforderlich?');emitInput()"
          :value="computedPhoneNumberRequired"
          :horizontal="false"
      />
      <div slot="footer">
        <CButton
            class="mr-3"
            color="success"
            variant="outline"
            @click="modalVisible = false"
        >
          Schließen
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import _ from 'lodash'
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import ColorPicker from "@/components/ColorPicker";
import LgcModuleConditionsPrivacy from "@/views/base/leadgenerator/lgc-modules/LgcModuleConditionsPrivacy";
import LgcModuleButton from "@/views/base/leadgenerator/lgc-modules/LgcModuleButton";
import Checkbox from "@/components/Checkbox";
export default {
  name: "LgcModulePersonalInfo",
  components: { LgcModuleButton, LgcModuleConditionsPrivacy, ColorPicker, LgcModule, Checkbox },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      modalVisible: false,
      dataHeader: this.value.header,
      buttonModuleProps: { value: { buttonText: this.value.buttonText, buttonColor: this.value.buttonColor || '#2d78a9' } },
      conditionsPrivacyModuleProps: { value: { conditionsPrivacyText: this.value.conditionsPrivacyText || '', conditionsPrivacyActive: this.value.conditionsPrivacyActive || false, conditionsPrivacyTextSize: this.value.conditionsPrivacyTextSize } },
      dataPhoneNumberRequired: typeof this.value.phoneNumberRequired === 'boolean' ? this.value.phoneNumberRequired : true
    }
  },
  computed: {
    computedPhoneNumberRequired () {
      return this.dataPhoneNumberRequired ? ['Telefonnummer erforderlich?'] : []
    },
  },
  methods: {
    inputEvent(key, e) {
      this[key] = e.target.innerText
      this.emitInput()
    },
    emitInput() {
      this.$emit('input', {
        value: {
          header: _.trim(this.dataHeader),
          buttonText: this.buttonModuleProps.value.buttonText,
          buttonColor: this.buttonModuleProps.value.buttonColor,
          conditionsPrivacyText: this.conditionsPrivacyModuleProps.value.conditionsPrivacyText,
          conditionsPrivacyActive: this.conditionsPrivacyModuleProps.value.conditionsPrivacyActive,
          conditionsPrivacyTextSize: this.conditionsPrivacyModuleProps.value.conditionsPrivacyTextSize,
          phoneNumberRequired: this.dataPhoneNumberRequired
        }
      })
    },
    onDoubleClick() {
      this.modalVisible = true
    }
  }
}
</script>

<style lang="scss">
.lgc-module-personalinfo {
  padding: 10px 20px;
  > h2 {
    margin: 0;
  }
  > .lgc-module-personalinfo--form > input {
    max-width: 100%;
    width: 100%;
    padding: 12px 14px;
    margin: -1px;
    border: 1px solid #bfbfbf;
    outline: 0;
    font-size: 16px;
    line-height: normal;
    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 4px 4px;
    }
  }
  .lgc-module-inside.lgc-module-conditions-privacy {
    p {
      margin-top: 6px;
      margin-bottom: 0;
    }
  }
}
</style>
