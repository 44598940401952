<template>
  <div>
    <div class="list-group">
      <div
          class="list-group-item"
          :revision="revision"
          v-for="(element, index) in computedList"
          :key="revision + '-' + element.order"
      >
        <span>{{ element.order }}</span>
        <div
            :class="'list-group-item-name' + (activePage === parseInt(element.order) -1 ? ' active' : '')"
            contenteditable="true"
            @focusout="changePageName(index, $event)"
            @click="$emit('pageClick', index)">
          {{ element.name }}
        </div>
      </div>
    </div>
    <CButton @click="$emit('newPage')" style="margin-top: 8px;margin-right: 4px" color="secondary">+ Schritt</CButton>
    <CButton @click="$emit('deleteCurrentPage')" style="margin-top: 8px;" color="danger"><CIcon name="cil-trash"/></CButton>
  </div>
</template>

<script>
export default {
  name: "LeadgeneratorOrderDraggable",
  props: {
    list: Array,
    activePage: Number
  },
  data() {
    return {
      drag: false,
      revision: 0,
    };
  },
  computed: {
    computedList() {
      this.revision++
      setTimeout(this.makeThemAllDraggable, 10)
      return this.list.map((name, index) => {
        return {name, order: index + 1};
      })
    }
  },
  mounted() {
    this.makeThemAllDraggable()
  },
  methods: {
    makeThemAllDraggable() {
      document.querySelectorAll('.list-group-item span').forEach(item => {
        this.dragElement(item)
      })
    },
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    dragElement(elmnt) {
      let startY = 0;
      let lastCountToMove = 0;

      const $this = this
      const elmntSpan = elmnt
      elmnt = elmnt.parentElement
      elmntSpan.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e.preventDefault();
        // get the mouse cursor position at startup:
        startY = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
        elmnt.parentNode.classList.add('hide-line-behind')
      }

      function elementDrag(e) {
        e.preventDefault();
        // calculate the new cursor position:

        const diff = e.clientY - startY
        const diffIsPositive = diff >= 0

        // set the element's new position:
        elmnt.style.transform = 'translate(0px, ' + diff + 'px)'
        elmnt.classList.add('no-transition')
        const countToMoveCalc = diffIsPositive ? (diff + 16) / 64 : (diff - 16) / 64
        const countToMove = diffIsPositive ? Math.floor(countToMoveCalc) : -Math.floor(Math.abs(countToMoveCalc))
        lastCountToMove = countToMove
        // console.log(diff, countToMoveCalc, lastCountToMove)

        if (countToMove) {
          document.querySelectorAll('.list-group-item').forEach(item => {
            if (item === elmnt) return
            item.style.transform = 'none'
          })
          let lastSibling = elmnt
          for (let i = 0; i < Math.abs(countToMove); i++) {
            lastSibling = (countToMove < 0) ? lastSibling.previousElementSibling : lastSibling.nextElementSibling
            if (lastSibling) {
              lastSibling.style.transform = `translate(0px, ${diffIsPositive ? '-' : ''}64px)`
            } else {
              break
            }
          }
        } else {
          document.querySelectorAll('.list-group-item').forEach(item => {
            if (item === elmnt) return
            item.style.transform = 'none'
          })
        }
      }

      function closeDragElement(e) {
        const diff = e.clientY - startY
        const diffIsPositive = diff >= 0
        const countToMoveCalc = diffIsPositive ? (diff + 16) / 64 : (diff - 16) / 64
        const countToMove = diffIsPositive ? Math.floor(countToMoveCalc) : -Math.floor(Math.abs(countToMoveCalc))

        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null
        elmnt.style.transform = `translate(0px, ${diffIsPositive ? '' : '-'}${countToMove * 64}px)`
        // elmnt.style.transition = null
        elmnt.parentNode.classList.remove('hide-line-behind')

        document.querySelectorAll('.list-group-item').forEach(item => {
          item.style.transform = 'none'
          item.classList.add('no-transition')
        })

        setTimeout(() => {
          if (countToMove) {
            let lastSibling = elmnt
            for (let i = 0; i < Math.abs(countToMove); i++) {
              if (countToMove < 0) {
                if (!lastSibling.previousElementSibling) break
                lastSibling = lastSibling.previousElementSibling
              } else {
                if (!lastSibling.nextElementSibling) break
                lastSibling = lastSibling.nextElementSibling
              }
            }
            if (countToMove < 0) elmnt.parentNode.insertBefore(elmnt, lastSibling)
            else elmnt.parentNode.insertBefore(elmnt, lastSibling.nextElementSibling)
          }
          const listGroupItems = document.querySelectorAll('.list-group-item')
          const newOrder = {}
          listGroupItems.forEach((item, index) => {
            newOrder[index] = parseInt(item.querySelector('span').innerText) -1 // only index
            item.classList.remove('no-transition')
          })
          setTimeout(() => $this.$emit('pageDragMouseUp', newOrder), 10)
        }, 1)
      }
    },
    changePageName(index, e) {
      this.$emit('changePageName', index, e.target.innerText)
    }
  }
};
</script>

<style>
.list-group {
  min-height: 20px;
  position: relative
}

.list-group-item {
  position: relative;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  transition: all 0.3s;
}

.list-group-item.no-transition {
  transition: none !important;
}

.list-group-item span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 2.5rem;
  border: 1px solid #05b9ae;
  border-radius: 0.375rem;
  color: #05b9ae;
  font-size: .75rem;
  line-height: 1rem;
  margin-right: 12px;
  background: #ffffff;
  cursor: move;
}

.list-group-item::after {
  content: '';
  border: 0 solid #05b9ae;
  border-right-width: 1px;
  position: absolute;
  top: 2.5rem;
  left: 13px;
  width: 0;
  height: 24px;
  margin: 12px 0;
  transition: all 0.2s;
}

.list-group.hide-line-behind .list-group-item::after {
  content: none !important;
}

.list-group-item:last-child::after {
  content: none;
}

.list-group:hover .list-group-item:after {
  border-right-color: #05b9ae;
}
.list-group-item-name {
  cursor: pointer;
  outline-offset: 2px;
  transition: font-weight 0.1s ease-out;
}
.list-group-item-name:hover, .list-group-item-name.active {
  font-weight: bold;
}
</style>
