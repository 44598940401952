<template>
  <div>
    <LgcModule
        class="lgc-module-input-range"
        @dblclick="modalVisible = true"
        @delete="$emit('delete', $event)"
        @add-below="$emit('add-below', $event)">
      <RangeSlider
          :unit="dataInputUnit"
          :value="computedInputDefault"
          :min="computedInputMin"
          :max="computedInputMax"
          :labelEvery="Math.floor(dataInputMax / 2)"
          :show-min-label="true"
          :show-alternative-input="dataInputShowAlternative"
          :font-color="dataInputFontColor"
          :background-color="dataInputBackgroundColor"
      />
    </LgcModule>
    <CModal
        title="Slider bearbeiten"
        size=""
        :show.sync="modalVisible"
        :closeOnBackdrop="true"
    >
      <div class="lgc-module-input--edit lgc-modal-edit">
        <CRow>
          <CCol>
            <CInput label="Einheit" v-model="dataInputUnit"/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <ColorPicker label="Primär" v-model="dataInputBackgroundColor" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <ColorPicker label="Schriftfarbe" v-model="dataInputFontColor" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput type="number" label="Mindestwert" :value="computedInputMin" @input="dataInputMin = Number($event)"/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput step="2" type="number" label="Maximalwert" :value="computedInputMax" @input="dataInputMax = Number($event)"/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput type="number" label="Standardwert" :value="computedInputDefault" @input="dataInputDefault = Number($event)"/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <BooleanCheckbox
                style="text-align: left"
                label=""
                name="show-alternative-input"
                text="Alternative Eingabe erlauben?"
                v-model="dataInputShowAlternative"
            />
          </CCol>
        </CRow>
      </div>
      <div slot="footer">
        <CButton
            class="mr-3"
            color="success"
            variant="outline"
            @click="modalVisible = false"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import ColorPicker from "@/components/ColorPicker";
import BooleanCheckbox from "@/components/BooleanCheckbox";
import _ from "lodash";
import RangeSlider from "@/components/RangeSlider";
import 'vue-slider-component/theme/default.css'

export default {
  name: "LgcModuleInputRange",
  components: {
    RangeSlider,
    ColorPicker,
    LgcModule,
    BooleanCheckbox
  },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      modalVisible: false,
      dataInputMin: this.value.inputMin || 0,
      dataInputMax: this.value.inputMax || 100,
      dataInputDefault: this.value.inputDefault || 50,
      dataInputUnit: this.value.inputUnit || 'm²',
      dataInputShowAlternative: this.value.inputShowAlternative || false,
      dataInputFontColor: this.value.inputFontColor || '#fff',
      dataInputBackgroundColor: this.value.inputBackgroundColor || '#2D78A9',
    }
  },
  computed: {
    computedInputMin() {
      return Number.parseInt(this.dataInputMin)
    },
    computedInputMax() {
      return Number.parseInt(this.dataInputMax)
    },
    computedInputDefault() {
      return Number.parseInt(this.dataInputDefault)
    }
  },
  methods: {
    buttonInsertSpace() {
      document.execCommand("insertText", false, ' ')
    },
    buttonInsertEnter() {
      document.execCommand("insertText", false, "\n")
    },
    emitInput() {
      this.$emit('input', {
        value: {
          inputMin: _.trim(this.dataInputMin),
          inputMax: _.trim(this.dataInputMax),
          inputDefault: this.dataInputDefault,
          inputUnit: this.dataInputUnit,
          inputShowAlternative: this.dataInputShowAlternative,
          inputFontColor: this.dataInputFontColor,
          inputBackgroundColor: this.dataInputBackgroundColor,

        }
      })
    }
  },
  watch: {
    modalVisible() {
      this.emitInput()
    }
  }
}
</script>

<style lang="scss" scoped>
.lgc-module-input-range {
  margin: 0;
  padding: 10px 20px;

  input {
    max-width: 100%;
    width: 100%;
    margin: -1px;
    border: 1px solid #bfbfbf;
    outline: 0;
    font-size: 16px;
    line-height: normal;
    border-radius: 4px;
  }
}
</style>
