<template>
  <div>
    <LgcModule
        class="lgc-module-quiz"
        @delete="$emit('delete', $event)"
        @add-below="$emit('add-below', $event)">
      <div @click="editCard(index)" class="lgc-module-quiz--card" v-for="(card, index) in cards">
        <div class="lgc-module-quiz--card-body">
          <div class="lgc-module-quiz--image"><img :src="card.image" alt="Bild hinzufügen..."/></div>
          <div class="lgc-module-quiz--title" @click.stop.prevent @focusout="inputEvent(card, $event)" contentEditable="true" v-text="card.title"></div>
        </div>
        <div @click.stop="addNewCard(card.id)" class="lgc-module-quiz--add-new">
          <div v-html="svgPlusIcon"></div>
        </div>
        <div @click.stop="deleteCard(card.id)" class="lgc-module-quiz--delete">
          <div v-html="svgDeleteIcon"></div>
        </div>
      </div>
    </LgcModule>
    <CModal
        title="Quiz bearbeiten"
        size=""
        :show.sync="cardEditVisible"
        :closeOnBackdrop="true"
    >
      <div class="lgc-module-quiz--edit">
        <h6 style="text-align: left">Vorhandene Bilder/Icons</h6>
        <div class="lgc-module-quiz--edit-choose-pre">
          <div v-for="img in predefinedImages" @click="cards[cardEditId].image = img">
            <img :src="img" alt="Bild hinzufügen..."/>
          </div>
        </div>
        <hr/>
        <CRow>
          <CCol>
            <ImageUploadField
                v-if="cardEditVisible"
                v-model="cards[cardEditId].image"
                label="Eigenes Bild"
                @delete="" />
          </CCol>
        </CRow>
      </div>
      <div slot="footer">
        <CButton
            class="mr-3"
            color="success"
            variant="outline"
            @click="cardEditVisible = false"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import _ from 'lodash'
import ImageUploadField from "@/components/ImageUploadField"
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
const svgPlusIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 191 104.23"><defs><style>.cls-1{fill:#22b3ab;stroke:#04786c;stroke-miterlimit:10;}.cls-2{fill:#fff;}</style></defs><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" x="0.5" y="0.5" width="190" height="103.23" rx="8"/><rect class="cls-2" x="92.9" y="13.15" width="5.2" height="77.94"/><rect class="cls-2" x="92.9" y="13.15" width="5.2" height="77.94" transform="translate(147.61 -43.39) rotate(90)"/></g></g></svg>'
const svgDeleteIcon = '<svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 191 104.2" style="enable-background:new 0 0 191 104.2;" xml:space="preserve"> <style type="text/css"> .st0{fill:#DC2F2F;stroke:#8E1A1A;stroke-miterlimit:10;} .st1{fill:#FFFFFF;} </style> <g id="Ebene_2_1_"> <g id="Ebene_1-2"> <path class="st0" d="M8.5,0.5h174c4.4,0,8,3.6,8,8v87.2c0,4.4-3.6,8-8,8H8.5c-4.4,0-8-3.6-8-8V8.5C0.5,4.1,4.1,0.5,8.5,0.5z"/> </g> </g> <path class="st1" d="M84.9,50.3c-0.1-1.7,1.3-3.2,3-3.3c1.7-0.1,3.2,1.3,3.3,3c0,0.1,0,0.2,0,0.3v23.3c0.1,1.7-1.3,3.2-3,3.3 c-1.7,0.1-3.2-1.3-3.3-3c0-0.1,0-0.2,0-0.3V50.3z M127.1,37.1l-3.2,42.7c-0.2,3.5-1.7,6.8-4.2,9.4c-2.4,2.5-5.8,3.9-9.2,3.8H80.5 c-3.5,0-6.8-1.4-9.2-3.8l0,0c-2.5-2.5-4-5.8-4.2-9.4l-3.2-42.7h-5.1v-9.2c0-2.2,1.1-3,3.2-3h15.1v-6.4c0-4,3.2-7.2,7.2-7.2h22.5 c3.9,0,7.1,3.2,7.2,7.2v6.4h15.5c0.2,0,0.4,0,0.7,0c1.2,0.2,2.1,1.2,2.2,2.3c0,0.1,0,0.3,0,0.4v9.4H127.1z M120.8,37.2H70.2 l3.2,42.1c0.1,2,1,3.9,2.4,5.4l0,0c1.3,1.3,3,2,4.8,2h29.9c1.8,0,3.5-0.7,4.8-2c1.4-1.5,2.3-3.4,2.4-5.4L120.8,37.2L120.8,37.2z M105.9,24.8v-5.6H85.1v5.6H105.9z M99.9,50.3c-0.1-1.7,1.3-3.2,3-3.3c1.7-0.1,3.2,1.3,3.3,3c0,0.1,0,0.2,0,0.3v23.3 c0.1,1.7-1.3,3.2-3,3.3s-3.2-1.3-3.3-3c0-0.1,0-0.2,0-0.3C99.9,73.6,99.9,50.3,99.9,50.3z"/></svg>'

export default {
  name: "LgcModuleQuiz",
  components: {
    LgcModule,
    ImageUploadField
  },
  data() {
    return {
      svgPlusIcon,
      svgDeleteIcon,
      cards: this.value,
      cardEditVisible: false,
      cardEditId: 0,
      predefinedImages: [
        'https://picsum.photos/seed/5/400/400',
        'https://picsum.photos/seed/6/400/400',
        'https://picsum.photos/seed/7/400/400',
        'https://picsum.photos/seed/8/400/400',
        'https://picsum.photos/seed/9/400/400',
        'https://picsum.photos/seed/10/400/400',
        'https://picsum.photos/seed/11/400/400',
        'https://picsum.photos/seed/12/400/400'
      ]
    }
  },
  props: {
    value: Array
  },
  methods: {
    editCard(index) {
      this.cardEditId = index
      this.cardEditVisible = true
    },
    inputEvent(card, e) {
      card.title = _.trim(e.target.innerText)
      this.emitCards()
    },
    addNewCard(id) {
      const cardIndex = _.findIndex(this.cards, { id })
      this.cards.splice(cardIndex + 1, 0, { title: "Neu!", image: "img/image_placeholder.png" })
      this.emitCards()
    },
    deleteCard(id) {
      this.cards = _.filter(this.cards, c => { return c.id !== id })
      this.emitCards()
    },
    emitCards() {
      // reorder and make id's based on new index
      this.cards.forEach((val, idx) => {
        val.id = idx + 1
      })
      this.$emit('input', {
        value: this.cards
      })
    }
  }
}
</script>

<style lang="scss">
.lgc-module-quiz {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 14px;
  row-gap: 14px;
  column-gap: 14px;

  .lgc-module-quiz--card {
    position: relative;
    display: flex;
    width: 46%;
    min-height: 200px;
    max-height: 200px;
    //border: 1px solid #333;
    border-radius: 8px;
    flex-direction: column;
    // overflow: hidden;
    transition: 0.1s all ease-in-out;
    //cursor: pointer;

    &:hover {
      outline: 2px dashed #46d7ce;
      outline-offset: 4px;
    }
    &:hover > .lgc-module-quiz--add-new, &:hover > .lgc-module-quiz--delete {
      opacity: 1;
      visibility: visible;
    }
  }
  .lgc-module-quiz--card-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #333;
  }
  .lgc-module-quiz--image {
    position: relative;
    height: 75%;
    cursor: pointer;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-drag: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
    }
  }
  .lgc-module-quiz--title {
    outline: none;
    width: 100%;
    height: 25%;
    font-size: 18px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 4px 0;
  }
  .lgc-module-quiz--add-new, .lgc-module-quiz--delete {
    position: absolute;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
    opacity: 0;
    visibility: hidden;
    bottom: -13px;
    left: 0;
    right: 30px;
    margin: auto;
    width: 28px;
    &:hover .cls-1 {
      fill: #16726d;
    }
  }
  .lgc-module-quiz--delete {
    left: 30px;
    right: 0;
    .st0 {
      fill: #dc2f2f;
    }
    &:hover .st0 {
      fill: #b71c1c;
    }
  }
}
.lgc-module-quiz--edit-choose-pre {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 0;
  grid-template-areas: ". . . .";
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  div {
    cursor: pointer;
    width: 90%;
    margin: 6px;
    transition: all 0.2s ease-out;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 50%);
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    &:hover {
      transform: scale(1.02);
    }
  }
  img {
    width: 100%;
  }
}
</style>
