<template>
  <div @dblclick="$emit('dblclick', $event)" :class="insideModule ? 'lgc-module-inside' : 'lgc-module'" @click="$emit('click', $event)">
    <slot></slot>
    <template v-if="!insideModule">
      <div @click="$emit('delete', $event)" class="lgc-module--delete" v-html="svgDeleteModuleIcon"></div>
      <div @click="$emit('add-below', $event)" class="lgc-module--add-below" v-html="svgPlusIcon"></div>
    </template>
  </div>
</template>

<script>
const svgPlusIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.23 104.23"><defs><style>.cls-1{fill:#22b3ab;stroke:#04786c;stroke-miterlimit:10;}.cls-2{fill:#fff;}</style></defs><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><g id="Ebene_2-2" data-name="Ebene 2"><g id="Ebene_1-2-2" data-name="Ebene 1-2"><path class="cls-1" d="M4.85.5H99.38c2.4,0,4.35,3.58,4.35,8V95.73c0,4.42-2,8-4.35,8H4.85c-2.4,0-4.35-3.58-4.35-8V8.5C.5,4.08,2.45.5,4.85.5Z"/><rect class="cls-2" x="45.74" y="13.15" width="12.76" height="77.94"/><rect class="cls-2" x="13.13" y="45.73" width="77.94" height="12.76"/></g></g></g></g></svg>'
const svgDeleteModuleIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.77 104.23"><defs><style>.cla-1{fill:#dc2f2f;stroke:#8e1a1a;stroke-miterlimit:10;}.cla-2{fill:#fff;}</style></defs><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><g id="Ebene_2-2" data-name="Ebene 2"><g id="Ebene_1-2-2" data-name="Ebene 1-2"><path class="cla-1" d="M5.21.5H107.57c2.6,0,4.7,3.58,4.7,8V95.73c0,4.42-2.1,8-4.7,8H5.21c-2.6,0-4.71-3.58-4.71-8V8.5C.5,4.08,2.61.5,5.21.5Z"/></g></g><path class="cla-2" d="M45.74,50.25A3.15,3.15,0,1,1,52,50a1.31,1.31,0,0,1,0,.28V73.57a3.15,3.15,0,1,1-6.29.28,1.31,1.31,0,0,1,0-.28ZM88,37.06,84.77,79.73a14.83,14.83,0,0,1-4.21,9.37,12.89,12.89,0,0,1-9.22,3.84H41.43a12.86,12.86,0,0,1-9.21-3.84h0A14.83,14.83,0,0,1,28,79.73L24.79,37.06H19.64V27.9c0-2.21,1.09-3,3.22-3H38v-6.4a7.18,7.18,0,0,1,7.17-7.17H67.65a7.19,7.19,0,0,1,7.15,7.17v6.39H90.31a4.92,4.92,0,0,1,.67,0,2.57,2.57,0,0,1,2.15,2.35v9.82Zm-6.31.11H31.1l3.16,42.1a8.56,8.56,0,0,0,2.37,5.38h0a6.67,6.67,0,0,0,4.78,2H71.32a6.65,6.65,0,0,0,4.77-2,8.51,8.51,0,0,0,2.39-5.38L81.69,37.2ZM66.82,24.85V19.29H46v5.56Zm-6.06,25.4A3.15,3.15,0,1,1,67.05,50v23.6a3.15,3.15,0,1,1-6.29.28,1.31,1.31,0,0,1,0-.28Z"/></g></g></svg>'
export default {
  name: 'LgcModule',
  props: {
    insideModule: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svgDeleteModuleIcon,
      svgPlusIcon
    }
  }
}
</script>

<style lang="scss">
.lgc-module {
  position: relative;
  padding: 10px;
  *[contenteditable="true"]{
    display: inline-block;
    white-space: pre-line;
    outline: 0;
  }
  &:hover {
    outline: 2px dashed #05b9ae;
    outline-offset: 4px;
    .lgc-module--delete, .lgc-module--add-below {
      opacity: 1;
      visibility: visible;
    }
  }
  .lgc-module--delete, .lgc-module--add-below {
    position: absolute;
    cursor: pointer;
    transition: 0.1s fill ease-in-out;
    opacity: 0;
    visibility: hidden;
    margin: auto;
    width: 28px;
    height: 28px;
  }
  .lgc-module--delete {
    right: -23px;
    top: 0;
    bottom: 0;
    &:hover .cla-1 {
      fill: #962626;
    }
  }
  .lgc-module--add-below {
    bottom: -8px;
    right: 0;
    left: 0;
    width: 18px;
    height: 18px;
    &:hover .cls-1 {
      fill: #16726d;
    }
  }
}
.lgc-modal-edit .form-group {
  text-align: left;
}
.lgc-module-inside {
  position: relative;
  padding: 10px;
}
</style>
